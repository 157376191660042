import React from 'react'
import Spinner from "../components/spinner";
import "./results.scss";
import { FaHeart, FaInstagram } from "react-icons/fa";
import * as graphql from "../apollo/graphql";
import { parseISO, format } from "date-fns";
import { useAppContext } from "../context";
import LoadingButton from "../components/loading-button";
import Media from '../components/media'
import fetch from 'isomorphic-unfetch'

const Recommendations = ({
  recommendations
}: {
  recommendations: {
    id: string;
    user: { first_name: string; last_name: string };
    submitter: { username: string };
  }[];
}) => {
  return (
    <div className="recommendations mb-4">
      <h5>Recommendations</h5>
      <div className="list-group">
        {recommendations.map(recommendation => {
          return (
            <div
              key={recommendation.id}
              className="recommendation list-group-item"
            >
              <strong>
                {recommendation.user.first_name} {recommendation.user.last_name}
              </strong>{" "}

              recommended <strong><a href={`https://www.instagram.com/${recommendation.submitter.username}/`} target="_blank" rel="noopener noreferrer" title="View user's profile on Instagram">@{recommendation.submitter.username}</a></strong>
            </div>
          );
        })}
      </div>
    </div>
  );
};




const Submission = ({ submission, voteCount }) => {
  const app = useAppContext();

  const [disableSubmission] = graphql.useDisableSubmissionMutation({
    refetchQueries: [{ query: graphql.PollResultsDocument }]
  });
  return (
    <div className="card box-shadow">
      <div className="card-img-top">
        <a href={submission.url} target="_blank" rel="noopener noreferrer">
          <Media image_url={submission.image_url} video_url={submission.video_url} />
        </a>
      </div>
      <ul className="vote-count list-group list-group-flush">
        <li className="list-group-item">
          <FaHeart /> {voteCount}
        </li>
        <li className="ig-info list-group-item">
          <div className="text-truncate">
            <a className="mr-2" href={submission.url} target="_blank" rel="noopener noreferrer" title="View on Instagram"><FaInstagram /></a>
            <a href={`https://www.instagram.com/${submission.username}/`} target="_blank" rel="noopener noreferrer" title="View user's profile on Instagram">@{submission.username}</a>
          </div>
        </li>
        {app.user?.role === "editor" && <li className="list-group-item d-flex justify-content-between align-items-center">
          <div>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() =>
                disableSubmission({
                  variables: { submission_id: submission.id }
                })
              }
            >Disable</button>
          </div>
        </li>}
      </ul>
      {/* <img className="card-img-top" {...bind} src={submission.image_url} /> */}
    </div>
  );
};



const DownloadPollArchiveButton = ({ poll_id, ...props }) => {
  const download = () =>
    fetch(`/api/download/poll/${poll_id}`).then(async response => {
      const data = await response.json()

      if (data.downloadUrl) {
        window.open(data.downloadUrl, "_self");
      }
    }).catch(console.error)

  return (
    <span className="download-poll" {...props}>
      <LoadingButton
        loadingChildren="Downloading Poll"
        callbackPromise={download}
      >
        Download
      </LoadingButton>
    </span>
  );
};

export default () => {
  const app = useAppContext();

  const [reopenPoll] = graphql.useReopenPollMutation()

  const { data, loading, error } = graphql.usePollResultsQuery({
    variables: { is_open_states: app.user?.role == "editor" ? [true, false] : [false] },
    pollInterval: app.user?.role == "editor" ? 2000 : undefined
  });

  if (loading)
    return (
      <div className="jumbotron">
        <div className="container">
          <Spinner />
        </div>
      </div>
    );

  return (
    <div className="result">
      <div className="jumbotron">
        <div className="container">
          <h1 className="jumbotron-heading">Results</h1>
          <p className="lead text-muted">
            Results from polls will show up here after they are closed.
          </p>
        </div>
      </div>
      <div className="results-body content-inner bg-light">
        <div className="container ">
          {data.polls.length == 0 && <p>No results yet. Check back later.</p>}
          {data.polls.map(poll => {
            const createdAtDate = poll.created_at && format(parseISO(poll.created_at), "PP")
            const closedAtDate = poll.closed_at && format(parseISO(poll.closed_at), "PP")
            const dateRange = [createdAtDate, closedAtDate].filter(e => !!e).join('-')

            return (
              <div key={poll.id} className="row">
                <div className="card col poll-result mb-4" data-id={poll.id}>
                  <div className="card-body">
                    <h3 className="card-title">Poll {dateRange}</h3>
                    {app.user?.role === "editor" && <p>
                      {poll.is_open ? <span className="badge badge-success">Open</span> : <span className="badge badge-secondary">Closed</span>}
                    </p>}

                    <p>
                      <span>
                        <strong>{poll.distinct_users_who_voted.aggregate.count}</strong> users cast {" "}
                        <strong>{poll.rankings_aggregate.aggregate.sum.vote_count}</strong> votes on {" "}
                        <strong>{poll.options.aggregate.count}</strong> submissions. The average user voted <strong>{poll.rankings_aggregate.aggregate.avg.vote_count.toFixed(2)}</strong> times.
                      </span>
                    </p>

                    {app.user?.role == "editor" && (
                      <p>
                        <DownloadPollArchiveButton className="mr-2" poll_id={poll.id} />
                        {!poll.is_open && <button className="btn btn-warning" onClick={() => reopenPoll({ variables: { poll_id: poll.id } })}>Reopen</button>}
                      </p>
                    )}

                    <h5>Winners</h5>
                    <div className="poll-results-inner mb-4">
                      {poll.winningRankings.nodes.map(ranking => {
                        return (
                          <Submission
                            key={ranking.submission.id}
                            submission={ranking.submission}
                            voteCount={ranking.vote_count}
                          />
                        );
                      })}
                    </div>

                    {poll.runnerUpRankings.nodes.length > 0 && <>
                      <h5>Runner Ups</h5>
                      <div className="poll-results-inner runner-ups mb-4">
                        {poll.runnerUpRankings.nodes.map(ranking => {
                          return (
                            <Submission
                              key={ranking.submission.id}
                              submission={ranking.submission}
                              voteCount={ranking.vote_count}
                            />
                          );
                        })}
                      </div>
                    </>}

                    {poll.recommendations?.length > 0 && (
                      <Recommendations
                        recommendations={poll.recommendations}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
