import React from "react"
import Results from "../containers/results"
import Layout from "../containers/layout"
import Private from "../components/private"

export default () => (
  <Private>
    <Layout>
      <Results />
    </Layout>
  </Private>
)
